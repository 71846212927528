import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { ComponentsModule } from '../components/components.module';
import { FormsModule } from '@angular/forms';

// for AngularFireDatabase
import { AngularFireDatabaseModule } from 'angularfire2/database';
// for AngularFireAuth
import { AngularFireAuthModule } from 'angularfire2/auth';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { Crop } from '@ionic-native/crop/ngx';

import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';

import '@ionic/pwa-elements';
import { Camera } from '@ionic-native/camera/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ImageProvider } from '../providers/image/image';
import { File } from '@ionic-native/file/ngx';
import { PopoverLoginComponent } from '../components/popover/popover-login/popover-login.component';

import { PopoverDropdownComponent } from '../components/popover/popover-dropdown/popover-dropdown.component';
import { PopoverStripeComponent } from '../components/popover/popover-stripe/popover-stripe.component';
import { Animation } from '@ionic/core';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import {IonicStorageModule} from '@ionic/storage';
import { CoreModule } from './core-module';
import { CheckoutComponent } from '../components/checkout/checkout.component';


@NgModule({
  declarations: [AppComponent, PopoverDropdownComponent, PopoverLoginComponent, PopoverStripeComponent, CheckoutComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [PopoverDropdownComponent, PopoverLoginComponent, PopoverStripeComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    CoreModule.forRoot(),

    IonicModule.forRoot({
     
      navAnimation: (AnimationC: Animation): Promise<Animation> => Promise.resolve(new AnimationC()),
      scrollPadding: true,
      scrollAssist: false
    }), 
    AppRoutingModule, AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, FormsModule, AngularFireAuthModule, AngularFireDatabaseModule,ComponentsModule,BrowserAnimationsModule,
    MaterialModule,FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(), HttpClientModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), 
  ],
  providers: [
    StatusBar,
    HttpClient,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ImagePicker,Crop,FileTransfer,Camera, ImageProvider,File
      
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/languages/', '.json');
}


