import { Component, OnInit, Input } from '@angular/core';
import { ProjectService, Project } from 'src/services/project.service';


@Component({
  selector: 'project-item-card',
  templateUrl: './project-item-card.component.html',
  styleUrls: ['./project-item-card.component.scss'],
})
export class ProjectItemCardComponent implements OnInit {

  @Input() project: Project;
  @Input() readOnly: boolean = false;
  @Input() borderColor: string;
  @Input() click;

  public imageSource: any =	'assets/img/bl-logo-grey.png';
  steamComponentLetters = ["S", "T", "E", "A", "M"];


  containsSteamComponent(comp){
    let hasComponent = false;

    for (let i = 0; i < this.project["steamComponents"].length; i++){
      if(this.project["steamComponents"][i].componentLetter == comp){
        hasComponent = true;
        break;
      }
    }

    return hasComponent;
  }


  constructor(public projectService: ProjectService) {
    
  }

  ngOnInit() {

    //We use the base64 string when we already have the image downloaded locally.
    if (this.project && this.project.iconLocalRef != null && this.project.iconLocalRef.base64 != null
      && this.project.iconLocalRef.base64 != ""){
      console.log(this.project.iconLocalRef.base64);
      this.imageSource = this.project.iconLocalRef.base64;
    }
    //This is the good (new) way of getting project images
    else if (this.project && this.project.iconDownloadUrl) {
      this.imageSource = this.project.iconDownloadUrl;
    }
    //This is the bad (old) way of getting project images.
    //We still use this (for now) so that old projects aren't broken.
    else if (this.project && this.project.id){
    
	    let firebaseStorageImgRef = this.projectService.getIconRefWithProjectId(this.project["id"]);
      //console.log(this.imageSource);
  
      firebaseStorageImgRef.getDownloadURL().then(url => {
        this.imageSource = url;
      });

      
    }

    if (this.imageSource == null || this.imageSource == ""){
      this.imageSource = 'assets/img/bl-logo-grey.png';
    }
  }

}
