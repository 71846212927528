import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {NavbarComponent} from './navbar/navbar.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import { ScrollEventModule } from 'ngx-scroll-event';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProjectItemCardComponent } from './project-item-card/project-item-card.component';
import { SteamLettersComponent } from './steam-letters/steam-letters.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { NewProjectButtonComponent } from './new-project-button/new-project-button.component';
import { CoreModule } from 'src/app/core-module';


@NgModule({
  declarations: [NavbarComponent, NewProjectButtonComponent, ImageCropperComponent, HeaderComponent, FooterComponent, ProjectItemCardComponent, SteamLettersComponent],
  imports: [
    CommonModule,
    CoreModule,
    IonicModule,
    ScrollEventModule,
    ImageCropperModule
  ],
  exports: [NavbarComponent, NewProjectButtonComponent, ImageCropperComponent, HeaderComponent, FooterComponent, ProjectItemCardComponent, SteamLettersComponent]
})
export class ComponentsModule { }
