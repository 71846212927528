import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserAuthService } from "../../../services/userAuth.service";
import { LanguageService } from 'src/services/language.service';
import { presentToast } from 'src/global';


@Component({
  selector: 'app-popover-login',
  templateUrl: './popover-login.component.html',
  styleUrls: ['./popover-login.component.scss'],
})
export class PopoverLoginComponent implements OnInit {

  translationName = "popover-login";

  controller: PopoverController;
  urlToGoto: string;

  email: string = "";
  password: string = "";

  //Placeholders
  emailErrorPlaceholder = "";
  passwordErrorPlaceholder = "";
  loginErrorPlaceholder = "";

  //Errors
  emailError = ".email-error";
  passwordError = ".password-error";
  loginErrorWrongCredentials = ".login-error-wrong-credentials";
  loginErrorNoEmail = ".login-error-no-email";
  loginErrorUnknown = ".login-error-unknown";

  currentTemplate = "login";

  constructor(private router: Router, public navParams: NavParams, public userAuth: UserAuthService
    , public languageService: LanguageService, public toastController: ToastController) {
    this.controller = this.navParams.get("controller");
  }

  changeTemplate(template){
    this.resetPlaceholders();
    this.currentTemplate = template;
  }

  getErrorText(error){
    let val = "";
    if (error != "" && error != null){
      val = this.languageService.getTranslation(this.translationName + error);
    }
    return val;
  }

  changePage(url){
    this.controller.dismiss('cancel');
    this.router.navigateByUrl(url);
  }

  resetPlaceholders(){
    this.emailErrorPlaceholder = "";
    this.passwordErrorPlaceholder = "";
    this.loginErrorPlaceholder = "";
  }

  login() {
    
    let canLogin = true;
    this.resetPlaceholders();

    if (!this.validateEmail(this.email)) {
      this.emailErrorPlaceholder = this.emailError;
      canLogin = false;
    }

    if (this.password == "") {
      this.passwordErrorPlaceholder = this.passwordError;
      canLogin = false;
    }

    if (canLogin){
      this.userAuth.signInWithEmail(this.email, this.password, this.urlToGoto, this.receiveLoginMessage, this);
    }
    
  }

  //Called from userAuth to let us know if the login was a success
  receiveLoginMessage(instance, error){

    if (error == null) {
      instance.controller.dismiss('cancel');
    }
    else {
      if (error.code == "auth/wrong-password" || error.code == "auth/user-not-found"){
        instance.loginErrorPlaceholder = instance.loginErrorWrongCredentials;
      }
      else{
        instance.loginErrorPlaceholder = instance.loginErrorUnknown;
      }
    }

    
  }

  resetPassword(){
    this.resetPlaceholders();
    if (!this.validateEmail(this.email)) {
      this.emailErrorPlaceholder = this.emailError;
    }
    else{
      this.userAuth.resetPassword(this.email, this.resetPasswordCallback, this);
    }
  }

  resetPasswordCallback(instance,error?){
    if (!error){
      presentToast(instance.languageService.getTranslation("toast.reset-password"), "purple", instance.toastController);
      instance.changeTemplate("login");
    }
    else if (error.code == "auth/user-not-found"){
      instance.loginErrorPlaceholder = instance.loginErrorNoEmail;
    }
  }

  validateEmail(email) {
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email.toLowerCase());
  }

  ngOnInit() {}


}
