import { Injectable, Query } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, QuerySnapshot, CollectionReference } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { UserAuthService } from './userAuth.service';
import { ToastController } from '@ionic/angular';
import { WhereFilterOp } from '@firebase/firestore-types';
import { presentToast } from '../global';
import { LanguageService } from './language.service';

export interface Project {
    id?: string;
    title: string;
    creatorEmail: string;
    globalCompetencies;
    steamComponents;
    unsdg;
    description: string;
    content: string;
    materials;
    needsFunding?: boolean;
    crowdFunding: {isCrowdfunding: boolean, moneyCollected: number, totalFundsNeeded: number, status: string, payments: [], isFilledOut: boolean};
    //Used to upload image to db. Does not get sent to db
    iconLocalRef?: {base64: string, blob: firebase.firestore.Blob, imageChanged: boolean};
    iconDownloadUrl: string;
    currentIndexInProjectCreator: number;
    status: string;
    progress: string;
    contributions;
    budget: number;
    createdAt: any;
    updatedAt: any;
}

export interface WhereCondition {
    field: string;
    condition: string;
    value;
}

@Injectable({
  providedIn: 'root'
})


export class ProjectService {

    private projectsCollection: AngularFirestoreCollection<Project>;
    private projects: Observable<DocumentChangeAction<Project>[]>;

    private db: AngularFirestore;

    constructor(db: AngularFirestore, public userAuth: UserAuthService
        , public toastController: ToastController, public languageService: LanguageService) {
        this.db = db;
        this.projectsCollection = db.collection<Project>('projects', ref => ref);
        //this.projects = this.projectsCollection.snapshotChanges();
    }

    getIconRefWithProjectId(id) {
        return firebase.storage().ref(`user-generated/projects/icons/${id}.jpeg`);
    }

    getEmptyProject() {
        /*const project: Project = {
            title: 'Colin test',
            creatorEmail: "",
            steamComponents: [{componentLetter: "T",description: "test",index: 1,name: "Technology"}],
            description: "The purpose of this project is to create a test project that looks like it's an actual project. Here is a real, and authentic description that was not made for test purposes or anything like that.",
            content: '<p>This is an awesome test project! Here we have a test video that display something about internet explorer or something, I donno I didn\'t watch it or anything, I\'m just trying to fill in content to make this look real.</p><p><a href="https://www.youtube.com/watch?v=AND5MMmbZsE">https://www.youtube.com/watch?v=AND5MMmbZsE</a></p><p><span class="fr-video fr-rv fr-fvc fr-dvb fr-draggable" contenteditable="false" draggable="true"><iframe width="640" height="360" src="https://www.youtube.com/embed/AND5MMmbZsE?&amp;wmode=opaque" frameborder="0" allowfullscreen="" class="fr-draggable"></iframe></span><br></p>',
            materials: [{name: "q", quantity: 2, unitCost: 27.77}],
            crowdFunding: {isCrowdfunding: false, totalFundsNeeded: 27.77, moneyCollected: 0},
            iconDownloadUrl: '',
            status: 'pending_review',
            progress: "incomplete",
            contributions: [],
            budget: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
        }*/

        
        const project: Project = {
            title: "",
            creatorEmail: "",
            steamComponents: [],
            globalCompetencies: [],
            unsdg: [],
            description: "",
            content: '',
            materials: [],
            crowdFunding: {isCrowdfunding: false, totalFundsNeeded: 0, moneyCollected: 0, status: "crowdfunding", payments: [], isFilledOut: false},
            iconDownloadUrl: '',
            currentIndexInProjectCreator: 0,
            status: 'pending_review',
            progress: "incomplete",
            contributions: [],
            budget: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
        }
        
        
/*
       const project: Project = {
        title: null,
        creatorEmail: "",
        steamComponents: null,
        unsdg: null,
        description: null,
        content: null,
        materials: null,
        crowdFunding: null,
        iconDownloadUrl: null,
        status: 'pending_review',
        progress: "incomplete",
        contributions: [],
        budget: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
    }
    */

        if (this.userAuth.getCurrentUser()){
            project.creatorEmail = this.userAuth.getCurrentUser().email;
        }
        project.id = this.db.createId();
        return project;
    }

    //Projects list
    //Get all projects
    //Allow query by stuff

    //Dashboard
    //Get project

    getWhereObject(field, condition, value){
        return <WhereCondition> {field: field, condition: condition, value: value};
    }

    //Get paginated list of all published projects
    getPublishedProjects(limit, lastProject?) {
        let whereList = [];
        whereList.push(this.getWhereObject("status", "==", "published"));
        return this.getProjectsPaginated(lastProject, limit, whereList);
    }

    //Get paginated list of published projects for current user
    getPublishedProjectsForCurrentUser(limit, lastProject?){
        let whereList = [];
        whereList.push(this.getWhereObject("status", "==", "published"));
        whereList.push(this.getWhereObject("creatorEmail", "==", this.userAuth.getCurrentUser().email));
        return this.getProjectsPaginated(lastProject, limit, whereList);
    }

    //Get list of incomplete projects for current user
    getIncompleteProjectsForCurrentUser(){
        let whereList = [];
        //whereList.push(this.getWhereObject("status", "==", "pending_review"));
        whereList.push(this.getWhereObject("status", "==", "incomplete"));
        whereList.push(this.getWhereObject("creatorEmail", "==", this.userAuth.getCurrentUser().email));
        return this.getProjectsUnpaginated(whereList);
    
    }

    //Get list of unpublished projects for current user
    getUnpublishedProjectsForCurrentUser() {
        let whereList = [];
        //whereList.push(this.getWhereObject("status", "==", "pending_review"));
        whereList.push(this.getWhereObject("status", "==", "pending_review"));
        whereList.push(this.getWhereObject("creatorEmail", "==", this.userAuth.getCurrentUser().email));
        return this.getProjectsUnpaginated(whereList);
    }

    //Get list of all unpublished projects with pagination
    getUnpublishedProjectsPaginated(limit, lastProject){
        if (!this.userAuth.getCurrentUser().isAdmin){
            return;
        }
        let whereList = [];
        whereList.push(this.getWhereObject("status", "==", "pending_review"));
        whereList.push(this.getWhereObject("progress", "==", "complete"));
        return this.getProjectsPaginated(lastProject, limit, whereList);
    }

    //Get list of all unpublished projects without pagination
    getUnpublishedProjectsUnpaginated(){
        if (!this.userAuth.getCurrentUser().isAdmin){
            return;
        }
        let whereList = [];
        whereList.push(this.getWhereObject("status", "==", "pending_review"));
        return this.getProjectsUnpaginated(whereList);
    }

    getProjectsBetweenTwoProjects(olderProject, newerProject, limit, status){
        return this.db.collection<Project>('projects', ref => 
        ref.orderBy('updatedAt', 'desc').where("status", "==", status).startAfter(olderProject).endBefore(newerProject).limit(limit)).get();
    }


    //All projects, no pagination
    private getProjectsUnpaginated(whereList: WhereCondition[]){
        let query: firebase.firestore.Query = this.db.collection<Project>("projects").ref;
        for (let i = 0; i < whereList.length; i++){
            query = query.where(whereList[i].field, <WhereFilterOp> whereList[i].condition, whereList[i].value);
        }
        return this.db.collection<Project>('projects', ref => query.orderBy('updatedAt', 'desc')).get();
    }

    //Paginated list of projects
    getProjectsPaginated(lastProject, limit: number, whereList?: WhereCondition[]){
        let query: firebase.firestore.Query = this.db.collection<Project>("projects").ref;

        //Dynamically add where clauses to query
      
        if (whereList != null){
            for (let i = 0; i < whereList.length; i++){
                query = query.where(whereList[i].field, <WhereFilterOp> whereList[i].condition, whereList[i].value);
            }
        }
        

        if (lastProject){
            return  this.db.collection<Project>('projects', ref => query.orderBy('updatedAt', 'desc').startAfter(lastProject).limit(limit)).get();
        }
        else{
            return this.db.collection<Project>('projects', ref => query.orderBy('updatedAt', 'desc').limit(limit)).get();
        }

    }

    getProjectFromSnapshot(snapshot){
        let project: Project;
        project = snapshot.payload.doc.data();
        project.id = snapshot.payload.doc.id;
        return project;
    }

    getProjectsCollectionQuery(field, operator, value){
        return this.db.collection<Project>('projects', ref => ref.where(field, operator, value));
    }
    
    getProjectsCollection() {
        return this.projectsCollection;
    }

    getProjectFromId(id) {
        return this.projectsCollection.doc<Project>(id).get();
    }

    getProjectFromDate(date){
        return this.db.collection<Project>('projects', ref => ref.where("updatedAt", "==", date)).get();
    }

    updateProject(id: string, project: Project){
        if (this.userAuth.getCurrentUser() == null){
            return;
        }
        project.updatedAt = new Date;
        let iconLocalRef = project.iconLocalRef;
        delete project.iconLocalRef;
        

        let updateRef = this.projectsCollection.doc(id).set(project).catch(error=>{
            console.log("error updating project: " + error);
        });
        project.iconLocalRef = iconLocalRef;
        return updateRef;

    }

    submitProject(project: Project, newProjectInstance) {
        //Make sure that the user is still logged in
        if (this.userAuth.getCurrentUser() == null){
            presentToast(this.languageService.getTranslation("toast.login-to-submit-project"), "danger", this.toastController);
            newProjectInstance.finishedPublish();
            return;
        }
        
        console.log("adding project");

        project.creatorEmail = this.userAuth.getCurrentUser().email;
        project.updatedAt = new Date;

        console.log("test1");

        if (project.title.includes("unpublish")){
            project.status = "pending_review";
        }

        else if (this.userAuth.isAdmin() == true){
            project.status = "published";
        }
        else{
            project.status = "pending_review";
        }

        if (project.iconLocalRef && project.iconLocalRef.imageChanged){
            let instance = this;

            console.log("test2");

            this.updateProjectImageStorage(project.id, project.iconLocalRef.blob).then(downloadURL => {
                //Image has been uploaded
                console.log("image uploaded!");
                console.log("icon local ref - " + project.iconLocalRef);
    
                //console.log("uploading project");
                
                delete project.iconLocalRef;
                project.iconDownloadUrl = downloadURL;

                console.log("local ref deleted - " + project.id + " - " + project);

                
                
                project.progress = "complete";

                instance.updateProject(project.id, project).then(snapshot=>{
                    presentToast(this.languageService.getTranslation("toast.project-uploaded"), "purple", this.toastController);
                    newProjectInstance.finishedPublish(true);
                    
                    //console.log("success");
                }).catch(error => {
                    console.log("error 3");

                    console.log(error);
                    newProjectInstance.finishedPublish();
                });
    
                
                
            }).catch(error =>{
                console.log("error 1");
                console.log(error);
                
                presentToast(this.languageService.getTranslation("toast.something-wrong"), "danger", this.toastController);
                newProjectInstance.finishedPublish();
            });
        }
        else{
            console.log("no image upload");
            this.updateProject(project.id, project).then(snapshot=>{
                presentToast(this.languageService.getTranslation("toast.project-uploaded"), "purple", this.toastController);
                
                //console.log("success");
            }).catch(error => {
                console.log(error);
                console.log("error 2");
                newProjectInstance.finishedPublish();
            });
        }


        
    }

    updateProjectImageStorage(id, blob){
        // Create a root reference
        var storageRef = firebase.storage().ref();

        console.log("uploading image");

        // Upload the file and metadata
        return storageRef.child('user-generated/projects/icons/' + id + ".jpeg").put(blob).then(function(){
            return storageRef.child('user-generated/projects/icons/' + id + ".jpeg").getDownloadURL();
        });
    }

    

    removeProject(id) {
        return this.projectsCollection.doc(id).delete();
    }

    updateAllProjectDates(){
        return;
        this.getProjectsCollection().snapshotChanges().subscribe(projectList =>{
            projectList.forEach(projectData => {
                let project = projectData.payload.doc.data();
                let id = projectData.payload.doc.id;

                console.log("Project - "  + project.title);

                if (project.createdAt == null){
                    project.createdAt = new Date();
                }
                setTimeout(() => 
                {
                this.updateProject(id,project);

                },
                1000);
            })
        })
    }

    deleteTestProjects(){
        console.log("deleting test projects");
        return;
        this.getProjectsCollection().snapshotChanges().subscribe(projectList =>{
            projectList.forEach(projectData => {

                let project = projectData.payload.doc.data();
                let id = projectData.payload.doc.id;
                
                setTimeout(() => 
                {
                    if (project.title.toLowerCase().includes("colin") ){
                        console.log(projectData.payload.doc.data().title);
                        this.removeProject(id);
                    }

                },
                1000);
            })
        })
    }

    getAllProjectCreators(){
        this.getProjectsCollection().get().subscribe(projectList =>{
            let names = "";

            projectList.forEach(projectData => {

                let project = <Project>projectData.data();
                let id = projectData.id;

                names += project.title + ": " + project.creatorEmail + "\n";
                
            })
            console.log(names);

        })
    }

    updateIndex(){
        return;
        this.getProjectsCollection().get().subscribe(projectList =>{
            projectList.forEach(projectData => {


                let project = <Project>projectData.data();
                let id = projectData.id;
                if (project.status == "pending_review"){
                    project.currentIndexInProjectCreator = 0;
                }
                else if (project.status == "published"){
                    project.currentIndexInProjectCreator = 8;
                }
                project.currentIndexInProjectCreator = 8;
                
                setTimeout(() => 
                {
                    console.log(project.title + ", " + project.currentIndexInProjectCreator);
                  

                    this.updateProject(id, project);

                },
                1000);
            })
        })
    }

    setPendingReviewToComplete(){
        return;
        this.getProjectsCollection().get().subscribe(projectList =>{
            projectList.forEach(projectData => {


                let project = <Project>projectData.data();
                let id = projectData.id;
                
                setTimeout(() => 
                {
                    //console.log(project.title);

                   // project.crowdFunding = {isCrowdfunding: false, moneyCollected: 0, totalFundsNeeded: 0};
                    //delete (project.needsFunding);

                    //project.progress = "complete";
                    //this.updateProject(id, project);

                },
                1000);
            })
        })
    }
    
}