import { Component, OnInit, Input } from '@angular/core';
declare var Stripe;
import { HttpClient } from "@angular/common/http";
import { presentToast } from 'src/global';
import { ToastController, PopoverController, LoadingController } from '@ionic/angular';
import { projectionDef } from '@angular/core/src/render3';
import { UserAuthService } from 'src/services/userAuth.service';
import { Project } from 'src/services/project.service';

@Component({
  selector: 'checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {

  stripe = Stripe('pk_test_2SyEzjMXFoMhvmnxhos5WY3d00u77usuGg');
  card: any;

  donationAmt;
  @Input() maxPayment;
  @Input() project: Project;
  @Input() popoverController: PopoverController;

  canMakePayment = true;

  //Does not represent whether the payment worked, just that the user sent a payment to the server and received a message.
  //Used to display message instead of credit card info when set to true
  paymentMade = false;
  paymentInfo = {};

  loadingOverlay;


  constructor(private http: HttpClient, public toastController: ToastController, public userAuth: UserAuthService, public loadingController: LoadingController) {
  }

  ngOnInit() {
    this.setupStripe();
 }

 closePopover(){
  this.popoverController.dismiss();
 }

 validateAmt(){
   if (this.donationAmt > this.maxPayment){
     this.donationAmt = this.maxPayment;
   }
   else if (this.donationAmt < 0){
     this.donationAmt = 0;
   }

 }

 setupStripe() {
   let elements = this.stripe.elements();
   var style = {
     base: {
       color: '#32325d',
       lineHeight: '24px',
       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
       fontSmoothing: 'antialiased',
       fontSize: '15px',
       '::placeholder': {
         color: '#aab7c4'
       }
     },
     invalid: {
       color: '#fa755a',
       iconColor: '#fa755a'
     }
   };

   this.card = elements.create('card', {style: style });
   console.log(this.card);
   this.card.mount('#card-element');

   this.card.addEventListener('change', event => {
     var displayError = document.getElementById('card-errors');
     if (event.error) {
       displayError.textContent = event.error.message;
     } else {
       displayError.textContent = '';
     }
   });

   var form = document.getElementById('payment-form');
   form.addEventListener('submit', event => {

    if (!this.canMakePayment){
      console.log("duplicate");
      return;
    }

    this.canMakePayment = false;
    let donation = this.donationAmt;

    if (typeof this.donationAmt != 'number'){
      presentToast("Invalid donation entered!", "danger", this.toastController);

    }
    else if (this.donationAmt <= 0){
      presentToast("Please enter a donation amount!", "danger", this.toastController);
      return;
    }

    else if (this.donationAmt > this.maxPayment){
      presentToast("You can't donate that much money!", "danger", this.toastController);
      return;
    }

     event.preventDefault();

     this.stripe.createToken(this.card).then(result => {
       if (result.error) {
         var errorElement = document.getElementById('card-errors');
         errorElement.textContent = result.error.message;
       } else {
         console.log(result.token);
         let id = result.token.id;
         //for testing
         //id = "tok_visa";
         this.makePayment(id, donation, this.project.id);
         this.displayLoadedOverlay();
       }
     });
   });

   
 }

 async displayLoadedOverlay(){
  this.loadingOverlay = await this.loadingController.create({
    message: 'Making purchase, please wait.....',
    spinner: "circles",
  });
  await this.loadingOverlay.present();
 }

  makePayment(tokenId, donation, projectId) {
    console.log("making payment");
    //convert to cents
    donation = donation * 100;
    let user = "";
    if (this.userAuth.isUserLoggedIn()){
      user = this.userAuth.getCurrentUser().email;
    }
    let instance = this;

    this.http.post('https://us-central1-brilliantlabs-app.cloudfunctions.net/payWithStripe', {
      amount: donation,
      currency: "cad",
      projectId: projectId,
      token: tokenId,
      user: user
    })
    .subscribe(data => {
      this.loadingOverlay.dismiss();
      this.paymentInfo = data;
      this.paymentMade = true;
      console.log(this.paymentInfo);
      this.project.crowdFunding.moneyCollected = this.paymentInfo['moneyCollected'];

    });
    }
  }