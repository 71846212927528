// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyC56HLichnFpY_QM-YMnOtnUxZpgo6Jl6w',
        authDomain: 'brilliantlabs-app.firebaseapp.com',
        databaseURL: 'https://brilliantlabs-app.firebaseio.com',
        projectId: 'brilliantlabs-app',
        storageBucket: 'brilliantlabs-app.appspot.com',
        messagingSenderId: '415239278842'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
