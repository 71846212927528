import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/services/userAuth.service';
import { ToastController } from '@ionic/angular';
import { changePage, presentToast } from '../../global';
import { LanguageService } from 'src/services/language.service';

@Component({
  selector: 'new-project-button',
  templateUrl: './new-project-button.component.html',
  styleUrls: ['./new-project-button.component.scss'],
})
export class NewProjectButtonComponent implements OnInit {

  constructor(private router: Router, public toastController: ToastController
    , public userAuth: UserAuthService, public languageService: LanguageService) { }

  ngOnInit() {}

  checkIfUserCanCreateProject(){
    if (this.userAuth.isUserLoggedIn()){
      changePage("projects/new", this.router);
    }
    else{
      presentToast(this.languageService.getTranslation("toast.login-to-create-project"), "purple", this.toastController);
    }
  }




}
