import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslateService, TranslatePipe, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const routes: Routes = [
  { path: '', redirectTo: '/projects-list', pathMatch: 'full' },
  /*{ 
    path: 'recipes',
    children: [
      {
        path: '',
        loadChildren: '../pages/recipes/recipes.module#RecipesPageModule'

      },
      {
        //Colon means anything can be after recipes. Ex: recipes/1. The id would now be 1
        path: ':recipeId',
        loadChildren: '../pages/recipes/recipe-detail/recipe-detail.module#RecipeDetailPageModule'
      }
    ]
  },*/
  { path: 'sign-up', loadChildren: '../pages/sign-up/sign-up.module#SignUpPageModule'},
  { path: 'sign-up/edit', loadChildren: '../pages/sign-up/sign-up.module#SignUpPageModule'},
  { path: 'projects-list', loadChildren: 'src/pages/projects-list/projects-list.module#ProjectsListPageModule'},
  { path: 'dashboard', loadChildren: '../pages/dashboard/dashboard.module#DashboardPageModule'},
  { path: 'projects/new/:id', loadChildren: '../pages/new-project/new-project.module#NewProjectPageModule'},
  { path: 'projects/new', loadChildren: 'src/pages/new-project/new-project.module#NewProjectPageModule'},
  { path: 'project-view', loadChildren: '../pages/project-view/project-view.module#ProjectViewPageModule' },
  { path: 'project-view/:id', loadChildren: '../pages/project-view/project-view.module#ProjectViewPageModule' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    TranslateModule
  ],
  exports: [RouterModule, TranslatePipe],
  providers: [TranslateModule]
})
export class AppRoutingModule {}
