import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover-dropdown',
  templateUrl: './popover-dropdown.component.html',
  styleUrls: ['./popover-dropdown.component.scss'],
})
export class PopoverDropdownComponent implements OnInit {

  

  //object with name, and colour
  //name is the text displayed
  props: any;

  constructor(public navParams:NavParams) {
    this.props = this.navParams.get("props");
  }

  ngOnInit() {}

  callbackFunction(index){
    this.navParams.get('componentRef').callback(index);
  }

}
