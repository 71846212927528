import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'steam-letters',
  templateUrl: './steam-letters.component.html',
  styleUrls: ['./steam-letters.component.scss'],
})
export class SteamLettersComponent implements OnInit {

  constructor(public languageService: LanguageService) { }

  @Input() steamComponents: any;
  @Input() inItemCard = false;

  ngOnInit() {}

  steamComponentLetters = ["S","T","E","A","M"];

  containsSteamComponent(comp){
    let hasComponent = false;

    if (this.steamComponents){
      for (let i = 0; i < this.steamComponents.length; i++){
        let letter = this.steamComponents[i].componentLetter;

        if(letter == comp){
          hasComponent = true;
          break;
        }
      }
    }
    

    return hasComponent;
  }

}
