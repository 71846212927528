import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserAuthService } from './userAuth.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages = [{"name" : "English", active: 1, translate: "en"}, {"name" : "Francais", active: 0, translate: "fr"}];


  constructor(private translate: TranslateService, public storage: Storage) {
    this.setDefaultLanguage("none"); 
  }

  public setLanguageFromUser(user){
    if (user && user.language){
      console.log("setting language from user: " + user.language);
      this.setLanguage(user.language);
    }
  }

  public getLanguageFromLocalStorage(){
    return this.storage.get("language");
  }

  public setLanguageFromLocalStorage(){
    this.storage.get('language').then((val) => {
      if (val){
        console.log('Your lang is', val);
        this.setLanguage(val);
      }
      else{
        console.log("val is null, setting to en");
        this.setLanguage("en");
      }
    });
  }


  public setLanguageLocalStorage(language){
    this.storage.set('language', language);
  }

  public setDefaultLanguage(language){
    this.translate.setDefaultLang(language);
    console.log("setting default lang to " + language);
    this.translate.use(language);
  }

  public setLanguage(language){
    console.log("setting lang to " + language);
    this.translate.use(language);
    for (let i = 0; i < this.languages.length; i++){
      if (this.languages[i].translate == language){
        this.languages[i].active = 1;
      }
      else{
        this.languages[i].active = 0;
      }
    }
  }


  public getTranslation(key){
    return this.translate.instant(key);
  }

  public getTranslationWithParams(key, paramsArray){
    if (paramsArray != null && paramsArray.length > 0){
      let params = {};
      
      for (let i = 0; i < paramsArray.length; i++){
        params["param" + (i + 1)] = paramsArray[i];
      }
      console.log(params);
      return this.translate.instant(key, params);
    }
    else{
      this.getTranslation(key);
    }
  }
}
