import { Component, OnInit, OnDestroy } from '@angular/core';
import { User, UserService } from "../../services/user.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  name: string = "not loaded";

  constructor(private userService: UserService) {
    
  }

   

  

  ngOnInit() {    
    //this.auth.user.forEach( state =>{
      /*if (state){
        this.loggedIn = true;
        //this.test = "Sign out";
        this.name = "logged in - " + state.name;
      }
      else{
        this.loggedIn = false;
        //this.test = "Login";
        this.name = "logged out";
        console.log(state);
      }
      this.loginCheck = true;*/

    //})
    
  }

}
