import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Platform } from '@ionic/angular';

import { DomSanitizer } from '@angular/platform-browser';
import '@ionic/pwa-elements';

import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ActionSheetController } from '@ionic/angular';

import { ImageProvider } from '../../providers/image/image';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { elementStyleProp } from '@angular/core/src/render3';
import { callCamera } from 'src/global';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit {

  @Input() defaultIconDownloadUrl = "";
  @Input() defaultIconBase64;
  @Output() submit = new EventEmitter<any>();

  translationName = "image-cropper";


  constructor(public sanitizer: DomSanitizer, private _IMAGE : ImageProvider, 
    public plt: Platform,private camera: Camera, public actionSheetController: ActionSheetController) {
    
    
    // Are we on mobile?
    if(this.plt.is('ios') || this.plt.is('android'))
    {
       this.platformIs = 'mobile';
    }
    // Or web?
    else
    {
       this.platformIs = 'browser';
    }
  }

  ngOnChanges() {
    console.log("cropper start");
    if (this.defaultIconDownloadUrl){
      this.getBase64ImageFromUrl(this.defaultIconDownloadUrl).then(result => {
        this.imageSource = result;
        this.displayCropper = true;
        console.log(result);
      })

    }
    else if (this.defaultIconBase64){
      if (this.imageSource == ""){
        console.log("add image");
        this.imageSource = this.defaultIconBase64;
        this.displayCropper = true;
      }
      
    }
    else {
      this.resetCroppedImage();
      this.imageSource = 'assets/img/no-image.jpg';
      this.displayCropper = false;
    }
    
  }

  imageChangedEvent: any = '';
  croppedImage: { base64: string, blob: Blob, imageChanged: boolean; } = {base64: "", blob: null, imageChanged: false};


  resetCroppedImage(){
    this.croppedImage = {base64: "", blob: null, imageChanged: false}
  }
  

  submitToParent(){
    this.submit.emit(this.croppedImage);
  }



  
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  public imageCropped(event: ImageCroppedEvent) {
    console.log("cropper: " + this.defaultIconDownloadUrl);
    //If we have a default image to load in, ignore the first crop as it happens on load
      this.croppedImage.base64 = event.base64;
      this.croppedImage.blob = event.file;
      this.croppedImage.imageChanged = true;
      console.log(this.croppedImage);
      this.submitToParent();
  }
  public imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  public loadImageFailed() {
      // show message
  }
    

  public imageSource: any =	'assets/img/no-image.jpg';

  public platformIs: string	=	'';

  displayCropper = false;

  ngOnInit() {
    
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }



  //Called from html input (desktop)
  selectImage(event: any): void
   {
      this._IMAGE
      
      .selectImage(event)
      .subscribe((res) =>
      {
         this.displayCropper = true;
         this.imageSource = res;
      });
   }

  //Opens gallery on android/ios
  openGallery () {
    let cameraOptions = {
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      destinationType: this.camera.DestinationType.FILE_URI,
      quality: 100,
      targetWidth: 1000,
      targetHeight: 1000,
      encodingType: this.camera.EncodingType.JPEG,
      correctOrientation: true
    }
   
    this.camera.getPicture(cameraOptions)
      .then((imageData) => {
        this.imageSource = (<any>window).Ionic.WebView.convertFileSrc(imageData);
      },
      err => console.log(err));  
   }

  async presentActionSheet() {
    
    let instance = this;
    const actionSheet = await this.actionSheetController.create({
      header: 'Get Image',
      buttons: [{
        text: 'Take Picture',
        role: 'close',
        icon: 'trash',
        handler: () => {
          callCamera(instance.camera, instance.camera.DestinationType.FILE_URI).then(imageData => {
            instance.imageSource = (<any>window).Ionic.WebView.convertFileSrc(imageData);

          });
        }
      }, 
       {
        text: 'Storage',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          this.openGallery();
        }
      }]
    });
    await actionSheet.present();
  }


}
