import { Camera, CameraOptions } from '@ionic-native/camera/ngx';


export const smallScreenSize = 991;

export async function presentToast(message, color, toastController) {
    const toast = await toastController.create({
      message: message,
      duration: 2000,
      color: color,
    });
    toast.present();
}

export function changePage(url, router){
  console.log("changing page to: " + url);
  router.navigateByUrl(url);
}


//Converts base 64 to blob. The content type I'm currently using is 'image/png'
export function b64toBlob  (b64Data, contentType='', sliceSize=512)  {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
    
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

//Opens camera on android/ios
//Format: Data URI = camera.DestinationType.FILE_URI, base64 = DestinationType.DATA_URL
export function callCamera(camera, format){
 
 const options: CameraOptions = {
   quality: 100,
   correctOrientation: true,
   encodingType: camera.EncodingType.JPEG,
   // imageData is either a base64 encoded string or a file URI
   // If it's base64 (DATA_URL):
   destinationType: format
 }
 
 return camera.getPicture(options);
}