import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { ToastController } from '@ionic/angular';
import { UserAuthService } from './userAuth.service';
import { SignUpPage } from 'src/pages/sign-up/sign-up.page';
import { LanguageService } from './language.service';
import { Location } from './location.service';
import { presentToast } from 'src/global';

export interface User {
    id?: string;
    email: string;
    firstName: string;
    lastName: string;
    province: string;
    role: string;
    locations: Location[];
    grades: string[];
    steam: string[];
    createdAt: Date;
    updatedAt: Date;
    isAdmin?: boolean;
    iconDownloadUrl: string;
    language: string;
    iconLocalRef?: {base64: string, blob: Blob, imageChanged: boolean};

}

@Injectable({
  providedIn: 'root'
})


export class UserService {

    private usersCollection: AngularFirestoreCollection<User>;
    private users: Observable<User[]>;

    private db: AngularFirestore;

    constructor(db: AngularFirestore, public toastController: ToastController, public languageService: LanguageService) {

        this.db = db;
        this.usersCollection = db.collection<User>('users', ref => ref/*.where('name', '==', 'colin')*/);

    }

    getEmptyUser() {
        const user: User = {
            email: "",
            firstName: "",
            lastName: "",
            province: "",
            role: "",
            locations: [],
            grades: [],
            steam: [],
            createdAt: new Date(),
            updatedAt: new Date(),
            iconDownloadUrl: "",
            language: "en",
            id: this.db.createId(),
        }
        this.languageService.getLanguageFromLocalStorage().then(val=>{
            if (val){
                user.language = val;
            }
        })
        return user;
    }

    updateLegacyUser(email, id){
        let user = this.getEmptyUser();
        user.email = email;
        user.id = id;
        console.log("creating legacy user!");

        this.createUser(user);
        return user;
    
    }

    getUsers() {
        return this.users;
    }

    getUsersCollectionQuery(field, operator, value){
        return this.db.collection<User>('users', ref => ref.where(field, operator, value));
    }
    
    getUsersCollection() {
        return this.usersCollection;
    }

    getUserFromId(id) {
        return this.usersCollection.doc<User>(id);
    }

    getUserFromEmail(email){
        return this.db.collection<User>('users', ref => ref.where("email", "==", email.toLowerCase()));
    }

    updateUserLanguage(user, language){
        if (user){
            user.language = language;
            this.updateUser(user);
        }
    }


     //ONLY CALL THIS FROM THE EMAILSIGNUP FUNCTION IN USERAUTHSERVICE!!!
     //THERE SHOULD NEVER BE USER DATA WITHOUT THEM BEING REGISTERED IN FIREBASE AUTH FIRST
    addUserFromEmailSignUp(newUser, password, callbackFunc: SignUpPage["receiveSubmitMessage"], instance, userAuth: UserAuthService){

        //No new profile image
        if (newUser.iconLocalRef == null || newUser.iconLocalRef.blob == null){
            this.createUserAndSignIn(newUser, password, callbackFunc, instance, userAuth);
        }
        //New profile image, upload image before adding user to db
        else{
            this.updateUserProfilePic(newUser.id, newUser.iconLocalRef.blob).then(downloadURL => {
                //Image has been uploaded
                console.log("image uploaded!");
    
                delete newUser.iconLocalRef;
                newUser.iconDownloadUrl = downloadURL;
                this.createUserAndSignIn(newUser, password, callbackFunc, instance, userAuth);
                    
                
            }).catch(error =>{
                callbackFunc(instance, error);
            });
        }
        
    }

    private createUserAndSignIn(newUser, password, callbackFunc, instance, userAuth){
        this.createUser(newUser).then(() => {
            //User was added, sign in
            callbackFunc(instance);
            //userAuth.signInWithEmail(newUser.email, password, null, null, null);
            
        }).catch(error => {
            callbackFunc(instance, error);
        });
    }


    public updateUser(user: User, instance?, callback?){
        console.log(user.id);
        user.updatedAt = new Date;
        if (user.iconLocalRef && user.iconLocalRef.blob){
            this.updateUserProfilePic(user.id,user.iconLocalRef.blob).then(downloadURL => {
                delete user.iconLocalRef;
                user.iconDownloadUrl = downloadURL;
                this.usersCollection.doc(user.id).set(user);
                if (callback){
                    presentToast(this.languageService.getTranslation("toast.account-updated"), "purple", this.toastController);
                    callback(instance);
                }
            })
        }
        else{
            this.usersCollection.doc(user.id).set(user);
            if (callback){
                presentToast(this.languageService.getTranslation("toast.account-updated"), "purple", this.toastController);
                callback(instance);
            }
        }
    }

    //DO NOT USE!!! Add user through userAuthService
    private createUser(user: User, userAuthService?: UserAuthService) {
        user.createdAt = new Date;
        user.updatedAt = new Date;

        if (userAuthService){
            return this.usersCollection.doc(user.id).set(user).then(()=>{
                userAuthService.addingUser = false;
            });

        }
        else{
            return this.usersCollection.doc(user.id).set(user);

        }
    }

    updateUserProfilePic(id, blob){
        // Create a root reference
        var storageRef = firebase.storage().ref();

        console.log("uploading image");

        // Upload the file and metadata
        return storageRef.child('user-generated/users/profile/' + id + ".jpeg").put(blob).then(function(){
            return storageRef.child('user-generated/users/profile/' + id + ".jpeg").getDownloadURL();
        }); 
           
    }

    removeUser(id) {
        return this.usersCollection.doc(id).delete();
    }

    removeUsers() {

        /*this.usersCollection.snapshotChanges().forEach(a => {
            let counter = 0;
            a.forEach(item => {
                counter ++;
                if (counter > 70){
                    return;
                }
                const data = item.payload.doc.data();
                if (data.name != "colinn") {
                    const id = item.payload.doc.id;
                    this.usersCollection.doc(id).delete();
                }
            });
        });*/
    }

    tutorialFunction() {
        //Never call this function
        let alwaysSetToTrue = true;
        if (alwaysSetToTrue) {
            return;
        }

        //LOOP THROUGH COLLECTION AND GET DATA READ ONLY

        //this is really weird but basically subscribe and forEach do the exact same thing
        //The first line is set to subscribe to make it more simple and this grabs one item, the whole collection of documents in one array
        this.users.subscribe((collection) => {
            //loop through every document

            collection.forEach(doc => {
                console.log(doc);

            })
        })

        //LOOP THROUGH COLLECTION AND GET DATA THAT YOU CAN UPDATE
        this.usersCollection.snapshotChanges().forEach(a => {
            a.forEach(item => {
                const id = item.payload.doc.id;
                console.log("id: " + id + ", data: " + item.payload.doc.data().firstName);
            });
        });

        /*
        * Get users without document id and join
        * this.userService.getUsers().subscribe(res => {

           this.users = res;

           this.users.forEach(user => {
               let pets: Observable<Pet[]> = this.petService.getPetsFromUserId(user.docId);
               pets.forEach(petsArray => {
                   user.pets = petsArray;
               })
           })

           

       });*/

        /*
         * Get users with document id and join
         * this.userService.getUsersCollection().snapshotChanges().forEach(usersArray => {


            usersArray.forEach(user => {
                const id = user.payload.doc.id;

                this.users.push(user.payload.doc.data());
                this.users[this.users.length - 1].docId = id;

                let pets: Observable<Pet[]> = this.petService.getPetsFromUserId(id);
                pets.forEach(petsArray => {
                    this.users[this.users.length - 1].pets = petsArray;
                })
            });
        });
    }
    */
    }
}