import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';

import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User, UserService } from './user.service';
import { FirebaseApp } from 'angularfire2';
import { SignUpPage } from 'src/pages/sign-up/sign-up.page';
import { ToastController } from '@ionic/angular';
import { changePage, presentToast } from '../global';
import { LanguageService } from './language.service';
import { elementStyleProp } from '@angular/core/src/render3';

@Injectable({ providedIn: 'root' })
export class UserAuthService {

    userFirebaseRef: any;
    private user: User;

    translationName = "user-auth";

    public loggedIn = null;
    public loginText = "";
    public loginCheck = false;
    public checkedForUser = false;
    
    public addingUser = false;

    constructor(private userService: UserService, private afAuth: AngularFireAuth, private afs: AngularFirestore
        , private router: Router, public toastController: ToastController, public languageService: LanguageService) {
        this.updateUserState();
        
    }

    getAuthEmail(){
        if (this.afAuth.auth.currentUser && this.afAuth.auth.currentUser.email){
            return this.afAuth.auth.currentUser.email;
        }
        else{
            return "";
        }
    }


    resetPassword(email, callback, instance){
        this.afAuth.auth.sendPasswordResetEmail(email).then(()=>{
            callback(instance);
        }).catch(error=>{
            callback(instance,error);
        })
    }

    isAdmin(){
        return this.user != null && this.user.isAdmin == true && this.isEmailedVerified() == true;
    }

    updateUser(){
        this.userService.updateUser(this.user);
    }

    updateUserState(){

        //Get user data from db if firebase user is signed in
        this.userFirebaseRef = this.afAuth.authState.pipe(
            switchMap(authUser => {
                //Auth has a current user signed in
                if (authUser) {
                    this.loggedIn = true;
                    this.loginText = this.languageService.getTranslation(this.translationName + ".sign-out");
                    this.loginCheck = true;

                    console.log("got user: " + authUser.email);
                    return this.userService.getUserFromEmail(authUser.email).valueChanges();
                } else {
                    this.loggedIn = false;
                    this.loginText = this.languageService.getTranslation(this.translationName + ".login");
                    this.loginCheck = true;
                    this.user = null;
                    this.checkedForUser = true;
                    console.log("null user");
                    return of(null);
                }
               
            })
        );

        if (this.userFirebaseRef != null){
            //Get user data from firebase ref
            this.userFirebaseRef.forEach(userData => {
                
                if (userData != null){
                    if (userData[0] == null && !this.addingUser && this.getAuthEmail() != ""){
                        this.addingUser = true;
                        this.user = this.userService.updateLegacyUser(this.getAuthEmail(), this.afs.createId());
                    }
                    else{
                        this.user = userData[0];
                    }
                    console.log(this.user);
                    console.log(this.isAdmin());
                    this.languageService.setLanguageFromUser(this.user);
                }
                else{
                    this.languageService.setLanguageFromLocalStorage();
                }
                this.checkedForUser = true;
            });
        }
        else{
            this.languageService.setLanguageFromLocalStorage();
        }
        
    }

    isEmailedVerified(){
        if (this.afAuth.auth.currentUser.emailVerified){
            return true;
            console.log("Email is verified");
        }
        else{
            return false;
            console.log("Email is not verified");
        }
    }

    isUserLoggedIn(){
        if (this.user != null && this.checkedForUser == true){
            return true;
        }
        console.log(this.user);
        return false;
    }

    getCurrentUser(){
        return this.user;
    }

    sendEmailVerification(){
        this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
            presentToast(this.languageService.getTranslation("toast.sent-email-verification"), "purple", this.toastController);
        }).catch(error => {
            console.log("Email verification not sent - " + error);
        });
    }

    //callback calls a function from the page you signed up in. instance is the instance of that page so you
    //can reference the correct instance of callback
    emailSignUp(newUser, password, callbackFunc: SignUpPage["receiveSubmitMessage"], instance) {
        this.addingUser = true;
        return this.afAuth.auth.createUserWithEmailAndPassword(newUser.email, password)
          .then(( user) => {
            
            newUser.id = user.user.uid;

            //Sign up worked on firebase auth
            
            this.userService.addUserFromEmailSignUp(newUser, password, callbackFunc, instance, this);
            this.sendEmailVerification();
          })
          //Couldn't add account to firebase auth
          .catch(error => {
              console.log(error);
              callbackFunc(instance, error);

              //TODO the purpose of this code was to check and see if when the user signed up, their
              //firebase account got added but their db account didn't for some reason. 
              //Instead of doing this, add their account via firebase function or just tell them something
              //went wrong and to update their profile
              
              //Email was already taken 
              //if (error.code == "auth/email-already-in-use"){
                //Check to see if something went wrong with creating the user document
                //during the initial sign up
                //this.userService.getUserFromEmail(newUser.email).get().forEach(doc => {
                //User account already exists, inform them
                //if (doc.size != 0){
                    //console.log("doc exists");
                    //callbackFunc(error, instance)
                //}
                //TODO User auth was created before, but something went wrong when adding
                //the user document. Add the document.
               // else{
                    //console.log("doc does not exist");
                    //this.signInWithEmail(newUser.email, password, "/dashboard", null, this, newUser);

                //}
               // });
            //}
          });
    }

    //Is called on sign up and on login. CallbackFunc calls login function if there is an error
    //such as "email has been taken" so it can display that message to the user
    //newuser is an edge case where their firestore auth was created but their document wasn't. Sign in, get the uid and create the doc
    signInWithEmail(email, password, pageToGoTo, callbackFunc, instance, newUser?) {
      return this.afAuth.auth.signInWithEmailAndPassword(email,
      password).then((auth) => {

        //console.log("success - sign in");
        this.updateUserState();
        if (pageToGoTo != null && pageToGoTo != ""){
        changePage(pageToGoTo, this.router);
        }
        if (callbackFunc && instance){
            callbackFunc(instance, null);
        }
        if (instance && newUser){
            newUser.id = auth.user.uid;
            instance.userService.addUserFromEmailSignUp(newUser, password, callbackFunc, instance, this);
        }
       
        return auth;
      })
      .catch(error => {
          if (callbackFunc && instance){
              callbackFunc(instance, error);
          }
      });
    }

    signOut(){
        this.afAuth.auth.signOut().then(() => {
            //console.log("success - sign out " + this.afAuth.user);
            this.updateUserState();

        })
        .catch(error => console.log(error));
        //return this.router.navigate(["/"]);
    }

}

