import { Component, OnInit } from '@angular/core';
import { UserAuthService } from "../../services/userAuth.service";
import { PopoverController } from '@ionic/angular';
import { PopoverLoginComponent } from '../popover/popover-login/popover-login.component'

import { PopoverDropdownComponent } from '../popover/popover-dropdown/popover-dropdown.component'
import { Router } from '@angular/router';
import { LanguageService } from 'src/services/language.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  translationName = "navbar";



  constructor(private router: Router, public auth: UserAuthService, 
    public popoverController: PopoverController, public languageService: LanguageService) { }

  ngOnInit() {}

  changePage(url){
    this.router.navigateByUrl(url);
  }

  

  navbarOpen = false;

  getLinkToProfilePage(){
    if (this.auth.isUserLoggedIn()){
      return "/sign-up/edit";
    }
    else{
      return "/sign-up";
    }
  }

  async presentLanguagePopover(ev: any){
    console.log(ev);
    this.presentPopover(ev, PopoverDropdownComponent, this.languageService.languages, "language-popover");
  }

  async presentLoginPopover(ev: any){
    this.presentPopover(null, PopoverLoginComponent, null, "login-popover");
  }

  async presentPopover(ev: any, popoverComponent, props, popoverClassName) {

    const popover = await this.popoverController.create({
      component: popoverComponent,
      event: ev,
      //This css classname is how you change things outside of the popover (opacity, width etc)
      //Until they make a better way to do it, you have to add the style to global.css
      //Format: popoverName-popover
      cssClass: popoverClassName,
      componentProps: {componentRef: this,
         props: props, controller: this.popoverController},
      translucent: true
    });
    return await popover.present();
  }

  callback(index){
    this.changeLanguage(index);
  }


  changeLanguage(index){
    this.popoverController.dismiss('cancel');
    console.log(this.languageService.languages[index].translate);
    this.languageService.setLanguageLocalStorage(this.languageService.languages[index].translate);
    if (this.auth.getCurrentUser()){
      this.auth.getCurrentUser().language = this.languageService.languages[index].translate;
      this.auth.updateUser();

    }
    else{
      console.log("testtt");
      this.languageService.setLanguage(this.languageService.languages[index].translate);

    }
  }

  changeLoginState(ev: any){
    if (this.auth.loginCheck){
      if (this.auth.loggedIn){
        this.auth.signOut();
      }
      else{
        this.presentLoginPopover(ev);
      }
    }
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

}
