import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LanguageService } from 'src/services/language.service';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/languages/', '.json');
  }
  
  @NgModule({
    declarations: [],
    imports: [
      CommonModule,
      TranslateModule.forChild({
        loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
  }),
    ],
    exports: [TranslateModule],
  })
  export class CoreModule {
  
    static forRoot(): ModuleWithProviders {
      return {
        ngModule: CoreModule,
        providers: [LanguageService]
      }
    }
  }